<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="9"
          class="pt-8 pl-8"
        >
          <h4 class="title">Data Akses Modul</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Data Akses Modul</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col  cols="12" sm="6" md="6">
                        <v-select item-text="nama" item-value="ugID" :items="lsUserGrup" label="User grup" 
                        v-model="ug_id" required v-on:change="fnModulX()" ></v-select>
                      </v-col>
                      <v-col  cols="12" sm="6" md="6">
                        <v-select item-text="nmGroup" item-value="id" :items="lsModulX" label="Nama Modul" 
                        v-model="mod_id" required></v-select>
                      </v-col>
                    </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveData">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-row class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                  <v-select item-text="nama" item-value="ugID" :items="lsUserGrup" label="User grup" 
                  v-model="ugID2" required v-on:change="gtUG()"></v-select>
                </v-col>
              <v-col cols="12" md="6">
                  <v-btn color="cyan" class="mb-0"  @click="fnBaru()" small>+Akses</v-btn>
                  <!--
                  <v-btn color="cyan" class="mb-0"  @click="fnBaru2" small>Group</v-btn>
                  -->
              </v-col>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :footer-props="{
                      'items-per-page-options': [50, 1000]
                    }"
                    :items-per-page="50"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="delData(item.id)">mdi-delete</v-icon>
                      <!--
                      <v-icon small class="mr-2" @click="fnEdit(item.id)">mdi-pencil </v-icon>
                      -->
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'

export default {
  data: () => ({
    snackbar: false, selected:'',
    valid: false,ug_id:null,ugID2:null, mod_id:null,
    nameRules: [
      v => !!v || 'Wajib diisi',
      v => (v && v.length >= 2) || 'Data salah',
    ],
    text: 'OK', text_cari :'',
    timeout: 2000,lsModulX:[],
    title:'', ta_id2:0, 
    loading: true, nama:'', urutan:0,
    lsData : [], mg_id:0, lsUserGrup :[],
    ftData : [], lsModulGrup:[],
    dialog : false, dialog2 : false,
    menuKat : false, st:'baru',
    dialogImpor:false,
    searchTextsLoading: true, 
    judul: '',
    direktori:'',
    headers: [
      { text: "Group", align: "start", sortable: false, value: "nmGroup" },
      { text: "Modul", value: "nmModul", sortable: false },
      { text: "Dir", value: "direktori", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ],    
    headers2: [
      { text: "Group", align: "start", sortable: false, value: "nama" },
      { text: "Urutan", value: "urutan", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nama.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    delData (id) {
      const dataku = {
        id: id
      }
      axios
        .post(api + 'delSAkses', dataku)
        .then(response => {
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            //this.konfirmHapus=false
            this.gtUG()
          } else {
            //console.log(' tidak sukses ')
          }
        })
    },
    saveData () {
      this.validate()
      if (this.valid===false) {
        this.text = 'Data salah atau tidak lengkap'
        this.snackbar =true
      } else {
        const data = {
          mod_id : this.mod_id,
          ug_id : this.ug_id
        }

        const dataku = {
          id:this.id,
          st:this.st,
          data : data
        }
        axios
          .post(api + 'saveSAkses', dataku)
          .then(response => {
            console.log('cek ', response.data, ' data ', dataku)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.gtUG()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    }, 
    async fnModulX() {
      axios
        .get(api + 'lsModulX&ug_id='+this.ug_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsModulX = response.data.data
          }
        })
    },
    async fnModulGrup() {
      axios
        .get(api + 'lsModulGrup')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsModulGrup = response.data.data
          }
        })
    },
    async fnUserGrup() {
      axios
        .get(api + 'lsUserGrup')
        .then(response => {
          console.log('lsUserGrup ', response.data)
          if (response.data.cek === 1) {
            this.lsUserGrup = response.data.data
          }
        })
    },
    async gtUG () {
      this.lsData = []
      this.ftData = []
      axios
        .get(api + 'lsAkses&ug_id=' + this.ugID2 )
        .then(response => {
          console.log('cek data lsAkses ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.ug_id=null
      this.clsForm()
      this.st = 'baru'
      this.dialog=true
    },

    async fnEdit(id) {
      const cekIndex = this.lsData.findIndex(cek => cek.id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.mg_id = dtEdit.mgID
      this.nama = dtEdit.nama
      this.st = 'edit'
      this.dialog = true
    },
    clsForm () {
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    this.fnUserGrup()
    this.fnModulX()

  }
}
</script>

<style lang="scss" scoped></style>
